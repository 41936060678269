<template>
  <a v-if="shouldDisplay" :href="imageLink">
    <img
      :id="uniqueId"
      :src="imageSrc"
      class="column is-results-ad"
      alt="advertisement"
    >
  </a>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    index: {
      type: Number,
      required: false,
    },
    adEveryXRecords: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  data: () => ({
    uniqueId: `result-ad-${window.performance.now()}-${Math.random().toString(36).substring(7)}`,
  }),
  computed: {
    ...mapState({
      ads: (state) => state.advertisements.results_ads,
    }),
    shouldDisplay() {
      return !!(// if
        this.ads?.length // there are ads
        && this.index > 0 // the index is not 0
        && this.index % this.adEveryXRecords === 0 // is congruent with 4
        && this.index / this.adEveryXRecords <= this.ads?.length // and has the correct count
      );
    },
    imageSrc() {
      return Array.from(this.ads)[this.index / this.adEveryXRecords - 1].src;
    },
    imageLink() {
      return Array.from(this.ads)[this.index / this.adEveryXRecords - 1].link;
    },
  },
};
</script>
